



































































































































































































































.btn-outline-danger:hover,
.btn-outline-green:hover {
  color: #fff;
}
.user-page {
  .content-profile {
    @media (max-width: 991px) {
      margin-top: 40px;
    }
  }
  .box-user {
    background-color: rgba(0, 0, 20, 0.5);
    border: 1px solid rgb(255 255 255);
    border-radius: 15px;
    color: #fff;
    margin-bottom: 20px;
    position: relative;
    .verify-status {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #b91c30;
      width: 155px;
      position: absolute;
      right: 0;
      top: 1.25rem;
      border-top-left-radius: calc(40px * 1.25);
      border-bottom-left-radius: calc(40px * 1.25);
      padding: 0 15px 0 5px;

      &.verify {
        background: #009750;
        .text-status {
          font-size: clamp(17px, 2.5vw, 20px);
        }
        .icon-status {
          background: rgba(255, 255, 255, 0.527);
        }
      }
      .icon-status {
        width: 30px;
        height: 30px;
        // background: rgba(255, 255, 255, 0.527);
        border: 1px solid rgba(255, 255, 255, 0.8);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        i,
        svg {
          width: 100%;
          height: 100%;
          color: #ccc;
        }
      }
      .text-status {
        color: #fff;
        font-weight: 600;
        font-size: 18px;
      }
    }
    .user {
      padding: 1.25rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      @media (max-width: 1440px) {
        padding: 10px;
      }
      .avatar {
        padding: 0.25rem;
        max-width: 100%;
        height: auto;
        width: 200px;
        margin-bottom: 1.25rem;
        img {
          width: 100%;
          height: 100%;
        }
        @media (max-width: 1440px) {
          width: 65px;
          height: 65px;
        }
        @media (max-width: 767px) {
          width: 45px;
          height: 45px;
        }
      }
      .info {
        border-bottom: 4px solid rgba(255, 255, 255, 0.1);
        padding-bottom: 1.25rem;
        width: 100%;
        .name {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #fff;
          margin-left: 5px;
          font-weight: 600;
          font-size: clamp(16px, 2vw, 17px);
          text-transform: uppercase;
          span {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #ffff00 !important;
            font-weight: 600;
            font-size: clamp(15px, 2vw, 18px);
            margin-left: 10px;
          }
          .btn-outline-green {
            border: 1px #00af00 solid !important;
            color: #00af00 !important;
            width: 100%;
            margin: 0;
          }
          .btn-outline-green:hover {
            background: #00af00 !important;
          }
          .btn-outline-danger {
            border: 1px #ec1c24 solid !important;
            color: #ec1c24 !important;
            width: 100%;
            margin: 0;
          }
          .btn:hover {
            color: #fff !important;
          }
        }
        .email {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #fff;
          margin-left: 5px;
          font-weight: 600;
          font-size: clamp(14px, 2vw, 15px);
          text-transform: uppercase;
          flex-wrap: wrap;
          span {
            color: #ffff00 !important;
            font-weight: 600;
            font-size: clamp(15px, 2vw, 18px);
            margin-left: 10px;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            max-width: calc(100% - 50px);
          }
        }
      }
    }
    .more-action {
      padding: 1.25rem 0;
      .nav-item-profile {
        margin: 10px 0;
        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-left: 3px solid transparent;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
          text-decoration: none;
          padding: 7px 20px;
          cursor: pointer;
          i,
          svg {
            width: 30px;
            display: inline-block;
            font-size: 20px;
            opacity: 0.8;
            vertical-align: middle;
            color: #fff;
            margin-right: 5px;
          }
          span {
            display: block;
            color: #fff;
          }
          &.active {
            span {
              color: #fff;
            }
            font-weight: 600;
            border-left-color: #008dff;
            background: linear-gradient(89deg, #fff3, #fff3);
          }
        }
      }
    }
  }
}
